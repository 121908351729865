import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Button from '@/components/Button';
import Container from '@/components/Container';
import ImageContainer from '@/components/ImageContainer';
import { Body, Title1 } from '@/components/Text';
import TrustpilotLabel from '@/modules/Home/TrustpilotLabel';

const LandingHeader = () => {
	const router = useRouter();
	const { t } = useTranslation('home');

	return (
		<div className="relative h-full lg:pt-2">
			<ImageContainer
				variant="customer-at-dealership"
				width="full"
				spacing="none"
				bgSize="cover"
				padding="none"
				wrapperClassName="h-full px-0 relative rounded-br-md overflow-hidden lg:rounded-0"
			>
				<div className="pt-9 mt-2 lg:mt-0 lg:pt-0 lg:w-1/2 relative lg:rounded-br-md">
					<div className="absolute inset-0 bg-primary-content opacity-70 mt-10 lg:mt-0 lg:rounded-br-md" />
					<Container
						width="default"
						variant="transparent"
						className="pt-3 pb-1.5 flex flex-col justify-center w-full min-h-full relative z-10 lg:pb-6 lg:pt-8 lg:px-6"
						wrapperClassName="rounded-br-md lg:pl-0.5"
					>
						<TrustpilotLabel variant="secondary" />
						<Title1 className="text-reverse uppercase mt-2">
							{t('forBusiness.landing.header')}
						</Title1>
						<Body className="my-1.5 text-reverse">{t('forBusiness.landing.description')}</Body>
						<div>
							<Button
								label={t('forBusiness.landing.registerBtn')}
								icon={faLongArrowRight}
								width="content"
								textClassName="text-primary-content"
								onClick={() => {
									router.push('/registration/dealership');
								}}
							/>
						</div>
					</Container>
				</div>
			</ImageContainer>
		</div>
	);
};

export default LandingHeader;
