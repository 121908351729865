import { Tab } from '@headlessui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Fragment, useEffect, useState } from 'react';

import Button from '@/components/Button';
import { Body, Caption } from '@/components/Text';
import { TabContent, TabKey } from '@/modules/Home/Business/index.d';
import OurNetwork from '@/modules/Home/Business/OurNetwork';
import PayLaterTabContent from '@/modules/Home/Business/PaymentOptions/Desktop/PayLaterTabContent';
import PayNowTabContent from '@/modules/Home/Business/PaymentOptions/Desktop/PayNowTabContent';
import PayPadTabContent from '@/modules/Home/Business/PaymentOptions/Desktop/PayPadTabContent';

const PaymentOptionsTabs = () => {
	const { t } = useTranslation('home');
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const router = useRouter();

	const handleTabChange = (index: number) => {
		setSelectedTabIndex(index);

		window.scrollTo({ behavior: 'smooth', top: 500 });
	};

	const tabContent: Record<TabKey, TabContent> = {
		payLater: {
			description: t('business.paymentOptions.payLater.description'),
			key: 'payLater',
			label: t('business.paymentOptions.payLater.label'),
			title: t('business.paymentOptions.payLater.title'),
		},
		payNow: {
			description: t('business.paymentOptions.payNow.description'),
			key: 'payNow',
			label: t('business.paymentOptions.payNow.label'),
			title: t('business.paymentOptions.payNow.title'),
		},
		payPad: {
			description: t('business.paymentOptions.payPad.description'),
			key: 'payPad',
			label: (
				<>
					<span className="text-primary">{t('business.paymentOptions.newLabel')}</span>{' '}
					{t('business.paymentOptions.payPad.label')}
				</>
			),
			title: t('business.paymentOptions.payPad.title'),
		},
	};

	const tabButtons = Object.entries(tabContent).map(([key, { label, title, description }]) => (
		<Tab key={key} as={Fragment}>
			{({ selected }) => (
				<div
					className={`text-left rounded-t-2 py-1.5 relative lg:w-96 border-t-3 border-tertiary hover:border-t-3 hover:border-constrast cursor-pointer ${
						selected
							? "bg-reverse border-t-3 border-x !border-accent after:absolute after:inset-x-0 after:border-b-[3px] after:-bottom-0.5 after:content-[' '] after:border-b-reverse outline-none"
							: 'bg-tertiary'
					}`}
				>
					<div className="px-1.5 py-0 font-bold">
						<Body weight="bold">{label}</Body>
					</div>
					<Button
						label={title}
						variant="none"
						className="w-auto"
						textClassName="text-n3 !font-bold"
						textPosition="justify-start"
					/>
					<Caption className="text-gray-600 text-sm px-1.5">{description}</Caption>
				</div>
			)}
		</Tab>
	));

	useEffect(() => {
		const getTabIndexFromProductQuery = (productQuery: string | undefined): number => {
			const tabIndexMap: { [key: string]: number } = {
				paylater: 0,
				paynow: 1,
				paypad: 2,
			};

			return typeof productQuery === 'string' ? tabIndexMap[productQuery.toLowerCase()] || 0 : 0;
		};

		setSelectedTabIndex(getTabIndexFromProductQuery(router?.query?.product as string));
	}, []);

	return (
		<Tab.Group selectedIndex={selectedTabIndex} onChange={handleTabChange}>
			<div className="hidden lg:block">
				{/* Desktop Tab Buttons */}
				<Tab.List>
					<div className="flex gap-4 justify-center mt-3 lg:px-5 border-tertiary border-b">
						{tabButtons}
					</div>
				</Tab.List>

				{/* Desktop Tab Content */}
				<div className="p-4">
					<Tab.Panels>
						<Tab.Panel>
							<PayLaterTabContent />
						</Tab.Panel>
						<Tab.Panel>
							<PayNowTabContent />
						</Tab.Panel>
						<Tab.Panel>
							<PayPadTabContent onTabChange={handleTabChange} />
						</Tab.Panel>
					</Tab.Panels>
				</div>
			</div>

			<OurNetwork />
		</Tab.Group>
	);
};

export default PaymentOptionsTabs;
