import { useTranslation } from 'next-i18next';

import Image from '@/components/Image';
import { Body, Title4 } from '@/components/Text/index';

const PayPadWorks = () => {
	const { t } = useTranslation('home');

	const items = [
		{
			description: t('business.paymentOptions.payPad.content.works.allYouNeedISChargingDock'),
			imgSrc: 'images/home/businessanddriver/customerAtDealership.png',
			title: t('business.paymentOptions.payPad.content.works.atTheCounter'),
		},
		{
			description: t('business.paymentOptions.payPad.content.works.wirelessAndReady'),
			imgSrc: 'images/home/businessanddriver/payPadCustomerAgree.png',
			title: t('business.paymentOptions.payPad.content.works.onTheFloor'),
		},
		{
			description: t('business.paymentOptions.payPad.content.works.takePayment'),
			imgSrc: 'images/home/businessanddriver/payPadCustomerPaying.png',
			title: t('business.paymentOptions.payPad.content.works.onDelivery'),
		},
	];

	return (
		<div className="border-y border-tertiary py-3 mt-3">
			<Title4 weight="bold" className="font-sans">
				{t('business.paymentOptions.payPad.content.works.title')}
			</Title4>
			<div className="flex pb-2 gap-1 mt-3 overflow-x-scroll snap-x snap-mandatory md:gap-0 lg:flex-row lg:scroll-none lg:overflow-visible lg:snap-none">
				{items.map((item) => (
					<div
						key={item.title}
						className="flex-shrink-0 w-full snap-center scroll-snap-child md:flex-shrink-1 lg:w-1/3 lg:pl-1 lg:pr-1"
					>
						<div>
							<Image src={item.imgSrc} width={343} height={313} layout="responsive" />
						</div>
						<Body weight="bold" className="py-1">
							{item.title}
						</Body>
						<Body>{item.description}</Body>
					</div>
				))}
			</div>
		</div>
	);
};

export default PayPadWorks;
