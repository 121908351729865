import { useTranslation } from 'next-i18next';

import Image from '@/components/Image';
import { Body, Title4 } from '@/components/Text';

const PayPadPayAnyway = () => {
	const { t } = useTranslation('home');

	const items = [
		{
			description: t(
				'business.paymentOptions.payPad.content.payAnyway.acceptMajorCreditAndDebitCards'
			),
			imageUrl: 'images/home/businessanddriver/pinForCards.png',
			title: t('business.paymentOptions.payPad.content.payAnyway.chipAndPinCards'),
		},
		{
			description: t('business.paymentOptions.payPad.content.payAnyway.allowYourCustomers'),
			imageUrl: 'images/home/businessanddriver/contactlessCards.png',
			title: t('business.paymentOptions.payPad.content.payAnyway.contactlessCards'),
		},
		{
			description: t('business.paymentOptions.payPad.content.payAnyway.seamlesslyIntegrate'),
			imageUrl: 'images/home/businessanddriver/payPadMobile.png',
			title: t('business.paymentOptions.payPad.content.payAnyway.mobileDevices'),
		},
		{
			description: t('business.paymentOptions.payPad.content.payAnyway.getPaidDirectly'),
			imageUrl: 'images/home/businessanddriver/payPadPayByBank.png',
			title: t('business.paymentOptions.payPad.content.payAnyway.payByBank'),
		},
	];

	return (
		<div className="py-3 lg:pb-8 lg:px-0">
			<Title4 weight="bold" className="font-sans mb-1 lg:mb-3">
				{t('business.paymentOptions.payPad.content.payAnyway.letThemPay')}
			</Title4>
			<div className="flex pb-2 gap-1 mt-3 overflow-x-scroll snap-x snap-mandatory md:gap-0 lg:flex-row lg:scroll-none lg:overflow-visible lg:snap-none">
				{items.map((item) => (
					<div
						key={item.title}
						className="flex-shrink-0 w-full snap-center scroll-snap-child md:flex-shrink-1 lg:pr-1 lg:w-1/4"
					>
						<div className="mb-1">
							<Image
								src={item.imageUrl}
								alt={item.title}
								width={239}
								height={171}
								layout="responsive"
							/>
						</div>
						<Body weight="bold" className="pb-0.5">
							{item.title}
						</Body>
						<Body>{item.description}</Body>
					</div>
				))}
			</div>
		</div>
	);
};

export default PayPadPayAnyway;
