import { useTranslation } from 'next-i18next';

import AttributedText from '@/components/AttributedText';
import { Body, LargerTitle, Title4 } from '@/components/Text';

const PayLaterStatisticsSection = () => {
	const { t } = useTranslation('home');

	const statsData = [
		{
			description: t('business.paymentOptions.payLater.content.stats.statsOne.description'),
			percentage: t('business.paymentOptions.payLater.content.stats.statsOne.percentage'),
		},
		{
			description: t('business.paymentOptions.payLater.content.stats.statsTwo.description'),
			percentage: t('business.paymentOptions.payLater.content.stats.statsTwo.percentage'),
		},
		{
			description: t('business.paymentOptions.payLater.content.stats.statsThree.description'),
			percentage: t('business.paymentOptions.payLater.content.stats.statsThree.percentage'),
		},
		{
			description: t('business.paymentOptions.payLater.content.stats.statsFour.description'),
			percentage: t('business.paymentOptions.payLater.content.stats.statsFour.percentage'),
		},
	];

	return (
		<div className="border border-tertiary mt-3 rounded-0.5 p-2 lg:mx-0 lg:p-4">
			<Title4 weight="bold">{t('business.paymentOptions.payLater.content.stats.title')}</Title4>
			<div className="flex flex-col lg:flex-row justify-center gap-2.5 mt-2">
				{statsData.map((stat) => (
					<div key={stat.percentage} className="flex flex-col text-left">
						<LargerTitle className="mb-0.5" variant="accent">
							{stat.percentage}
						</LargerTitle>
						<AttributedText
							wrapper={Body}
							wrapperProps={{
								className: 'inline',
								weight: 'bold',
							}}
							className="text-content"
						>
							{stat.description}
						</AttributedText>
					</div>
				))}
			</div>
		</div>
	);
};

export default PayLaterStatisticsSection;
