import {
	faCarSide,
	faSparkles,
	faTruck,
	faUserHelmetSafety,
	faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import React from 'react';

import Icon from '@/components/Icon';
import { Body, Title4 } from '@/components/Text';

const TradeAndRetailCustomers = () => {
	const { t } = useTranslation('home');

	return (
		<div className="border border-tertiary mt-3 rounded-0.5 p-2 lg:mx-0 lg:p-4 mb-3 lg:mb-auto">
			<Title4 weight="bold" className="font-sans">
				{t('business.paymentOptions.payLater.content.tradeAndRetail.title')}
			</Title4>
			<div className="flex flex-col gap-1 mt-2 lg:flex-row">
				<div className="flex flex-col items-start">
					<div className="flex items-center">
						<Icon iconName={faUserHelmetSafety} className="text-xl text-gray-700 p-0.5" />
						<Icon iconName={faTruck} className="text-xl text-gray-700 p-0.5" />
					</div>
					<div className="flex items-center gap-1">
						<Body className="text-lg font-semibold">
							{t('business.paymentOptions.payLater.content.tradeAndRetail.tradeCustomers')}
						</Body>
						<Body weight="bold" className="bg-primary py-0.25 px-0.75 rounded-0.5">
							<Icon iconName={faSparkles} />
							{t('business.paymentOptions.payLater.content.tradeAndRetail.tradeCustomersLabel')}
						</Body>
					</div>

					<p className="text-gray-600">
						{t('business.paymentOptions.payLater.content.tradeAndRetail.description')}
					</p>
				</div>
				<div>
					<Icon iconName={faUsers} className="text-xl text-gray-700 p-0.5" />
					<Icon iconName={faCarSide} className="text-xl text-gray-700 p-0.5" />
					<div>
						<Body weight="bold">
							{t('business.paymentOptions.payLater.content.tradeAndRetail.retailCustomers')}
						</Body>
						<p>{t('business.paymentOptions.payLater.content.tradeAndRetail.softCreditCheck')}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradeAndRetailCustomers;
