import { useTranslation } from 'next-i18next';

import Image from '@/components/Image';
import { Body, Title4 } from '@/components/Text/index';

const LandingIntegrationWorkflow = () => {
	const { t } = useTranslation('home');

	const cardsData = [
		{
			description: t(
				'business.paymentOptions.landingPage.integratesInYourWorkflows.cardsData.embedAllPaymentMethods'
			),
			imagePath: 'images/home/businessanddriver/vehicleHealthCheck.png',
			title: t(
				'business.paymentOptions.landingPage.integratesInYourWorkflows.cardsData.vehicleHealthCheck'
			),
		},
		{
			description: t(
				'business.paymentOptions.landingPage.integratesInYourWorkflows.cardsData.saveTimeInitiating'
			),
			imagePath: 'images/home/businessanddriver/dealerManagementSystem.webp',
			title: t(
				'business.paymentOptions.landingPage.integratesInYourWorkflows.cardsData.dealerManagement'
			),
		},
		{
			description: t(
				'business.paymentOptions.landingPage.integratesInYourWorkflows.cardsData.offerPaymentFlexibility'
			),
			imagePath: 'images/home/businessanddriver/bookingFlows.png',
			title: t(
				'business.paymentOptions.landingPage.integratesInYourWorkflows.cardsData.bookingFlows'
			),
		},
	];

	return (
		<div className="hidden lg:block border-t border-tertiary pt-3 mt-3">
			<Title4 className="font-sans" weight="bold">
				{t('business.paymentOptions.landingPage.integratesInYourWorkflows.titleMain')}
			</Title4>
			<div className="flex pb-2 mt-3 overflow-x-scroll snap-x snap-mandatory lg:flex-row lg:scroll-none lg:overflow-visible lg:snap-none">
				{cardsData.map((card) => (
					<div
						key={card.title}
						className="flex-shrink-0 w-full pl-1 pr-0.5 snap-center scroll-snap-child lg:w-1/3"
					>
						<div className="border-3 border-primary-content rounded-xs flex items-center object-contain relative md:h-[240px]">
							{card.imagePath ? (
								<Image
									src={card.imagePath}
									alt={card.title}
									layout="fill"
									className="w-full object-cover"
								/>
							) : null}
						</div>
						<div className="break-words mt-0.75">
							<Body className="text-lg font-semibold">{card.title}</Body>
							<Body className="text-gray-600">{card.description}</Body>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default LandingIntegrationWorkflow;
