import { useTranslation } from 'next-i18next';
import React from 'react';

import { Body, Title1, Title4 } from '@/components/Text';

const BenefitsSection = () => {
	const { t } = useTranslation('home');

	const benefitsData = [
		{
			description: t('business.paymentOptions.payNow.content.benefits.savedPerCustomer'),
			value: t('business.paymentOptions.payNow.content.benefits.fiftyMins'),
		},
		{
			description: t('business.paymentOptions.payNow.content.benefits.savedInAnnualTransaction'),
			value: t('business.paymentOptions.payNow.content.benefits.twentyFourTousands'),
		},
	];

	return (
		<div className="my-3 py-3 rounded-xs">
			<Title4 weight="bold" className="mb-3">
				{t('business.paymentOptions.payNow.content.benefits.partnerBenefits')}
			</Title4>
			<div className="flex gap-3 mt-3">
				{benefitsData.map((benefit) => (
					<div key={benefit.value} className="w-1/4">
						<Title1 variant="secondary-contrast">{benefit.value}</Title1>
						<Body>{benefit.description}</Body>
					</div>
				))}
			</div>
		</div>
	);
};

export default BenefitsSection;
