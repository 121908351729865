import LandingIntegrationWorkflow from '@/modules/Home/Business/LandingIntegrationWorkflow';
import BenefitsSection from '@/modules/Home/Business/PaymentOptions/Desktop/PayNowTabContent/BenefitsSection';
import PayNowEnables from '@/modules/Home/Business/PaymentOptions/Desktop/PayNowTabContent/PayNowEnables';
import PayNowInfo from '@/modules/Home/Business/PaymentOptions/Desktop/PayNowTabContent/PayNowInfo';

const PayNowTabContent = () => (
	<div className="lg:px-5">
		<PayNowInfo />
		<PayNowEnables />
		<BenefitsSection />
		<LandingIntegrationWorkflow />
	</div>
);

export default PayNowTabContent;
