import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import Icon from '@/components/Icon';
import Image from '@/components/Image';
import { Body, LargeBody } from '@/components/Text';
import OurNetwork from '@/modules/Home/Business/OurNetwork';

const PaymentOptionButtons = () => {
	const { t } = useTranslation('home');

	const items = [
		{
			description: t('business.paymentOptions.payLater.description'),
			imageHeight: 177,
			imageSrc: 'images/home/businessanddriver/payLaterIphone.webp',
			imageWidth: 128,
			key: 'payLater',
			label: t('business.paymentOptions.payLater.label'),
			title: t('business.paymentOptions.payLater.title'),
			url: 'for-business/paylater',
		},
		{
			description: t('business.paymentOptions.payNow.description'),
			imageHeight: 177,
			imageSrc: 'images/home/businessanddriver/payNowIphoneMobile.png',
			imageWidth: 128,
			key: 'payNow',
			label: t('business.paymentOptions.payNow.label'),
			title: t('business.paymentOptions.payNow.title'),
			url: 'for-business/paynow',
		},
		{
			description: t('business.paymentOptions.payPad.description'),
			imageHeight: 87,
			imageSrc: 'images/home/businessanddriver/payPadPosMobile.webp',
			imageWidth: 128,
			key: 'payPad',
			label: (
				<>
					<span className="text-primary">{t('business.paymentOptions.payPad.labelTag')}</span>{' '}
					{t('business.paymentOptions.payPad.label')}
				</>
			),
			title: t('business.paymentOptions.payPad.title'),
			url: 'for-business/paypad',
		},
	];

	return (
		<div className="flex flex-col">
			{items.map((item) => (
				<Link key={item.key} href={item.url}>
					<div className="flex items-center justify-between px-1.5 py-2 border-b border-tertiary last:border-b-0 min-h-14">
						<div className="w-5/12">
							<Body weight="bold" className="pb-0.25">
								{item.label}
							</Body>
							<LargeBody weight="bold" className="pb-0.25">
								{item.title}
							</LargeBody>
							<Body>{item.description}</Body>
						</div>
						<div>
							<Image
								src={item.imageSrc}
								alt={item.title}
								width={item.imageWidth}
								height={item.imageHeight}
							/>
						</div>
						<div>
							<Icon iconName={faChevronRight} size="2x" variant="accent" />
						</div>
					</div>
				</Link>
			))}
			<OurNetwork />
		</div>
	);
};
export default PaymentOptionButtons;
