import { faLongArrowRight } from '@fortawesome/pro-solid-svg-icons';
import router from 'next/router';
import { useTranslation } from 'next-i18next';

import Button from '@/components/Button';
import Image from '@/components/Image';
import Stepper from '@/components/Stepper';
import IStepper from '@/components/Stepper/index.d';
import { Caption, LargeButton, Title2, Title4 } from '@/components/Text/index';

const PayLaterInfo = () => {
	const { t } = useTranslation('home');

	const stepsData = [
		{
			content: t('business.paymentOptions.payLater.content.info.stepOne.content'),
			id: 1,
			title: t('business.paymentOptions.payLater.content.info.stepOne.title'),
		},
		{
			content: t('business.paymentOptions.payLater.content.info.stepTwo.content'),
			id: 2,
			title: t('business.paymentOptions.payLater.content.info.stepTwo.title'),
		},
		{
			content: t('business.paymentOptions.payLater.content.info.stepThree.content'),
			id: 3,
			title: t('business.paymentOptions.payLater.content.info.stepThree.title'),
		},
	];

	return (
		<div className="px-0">
			<div>
				<Image remote src="images/logos/bumper-main.svg" height={20} width={79} />
				<Title2>{t('business.paymentOptions.payLater.content.info.title')}</Title2>
			</div>
			<div className="flex lg:gap-5 lg:justify-between flex-col-reverse lg:flex-row">
				<div className="my-3 lg:w-1/2">
					<LargeButton>
						{t('business.paymentOptions.payLater.content.info.description')}
					</LargeButton>
					<div className="my-2">
						<Title4 weight="bold" variant="reverse">
							{t('business.paymentOptions.payLater.content.info.noRiskToBusiness')}
						</Title4>
						<Title4 weight="bold" variant="reverse">
							{t('business.paymentOptions.payLater.content.info.noWorriesToCustomers')}
						</Title4>
					</div>
					<Caption weight="bold">
						{t('business.paymentOptions.payLater.content.info.stepsTitle')}{' '}
					</Caption>{' '}
					<br />
					<Stepper itemList={stepsData as IStepper.IItem[]} />
				</div>

				<div className="lg:w-1/2 w-4/5 flex justify-center mx-auto">
					<div className="hidden lg:block">
						<Image
							src="images/home/businessanddriver/payLaterIphone.webp"
							width={500}
							height={700}
						/>
					</div>
					<div className="block lg:hidden mt-2">
						<Image
							src="images/home/businessanddriver/payLaterIphone.webp"
							width={266}
							height={348}
						/>
					</div>
				</div>
			</div>
			<Button
				onClick={() => {
					router.push('/registration/dealership');
				}}
				label={t('business.paymentOptions.payLater.content.info.registerBtnText')}
				icon={faLongArrowRight}
				width="content"
				type="button"
			/>
		</div>
	);
};

export default PayLaterInfo;
