import { FC } from 'react';

import PayPadInfo from '@/modules/Home/Business/PaymentOptions/Desktop/PayPadTabContent/PayPadInfo';
import PayPadMethods from '@/modules/Home/Business/PaymentOptions/Desktop/PayPadTabContent/PayPadMethods';
import PayPadPayAnyway from '@/modules/Home/Business/PaymentOptions/Desktop/PayPadTabContent/PayPadPayAnyway';
import PayPadWorks from '@/modules/Home/Business/PaymentOptions/Desktop/PayPadTabContent/PayPadWorks';

type IProps = {
	onTabChange: (index: number) => void;
};

const PayPadTabContent: FC<IProps> = ({ onTabChange }) => (
	<div className="lg:px-5">
		<PayPadInfo />
		<PayPadMethods onTabChange={onTabChange} />
		<PayPadWorks />
		<PayPadPayAnyway />
	</div>
);

export default PayPadTabContent;
