import {
	faBank,
	faBolt,
	faCreditCard,
	faMobileAlt,
	faSparkles,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';

import Image from '@/components/Image';
import { Body, Title4 } from '@/components/Text';

const PayNowEnables = () => {
	const { t } = useTranslation('home');

	const cardsData = [
		{
			description: t('business.paymentOptions.payNow.content.payNowEnables.getPaidDirectly'),
			icon: faBank,
			logos: [
				'images/logos/banking/bank1.png',
				'images/logos/banking/bank2.png',
				'images/logos/banking/bank3.png',
				'images/logos/banking/bank4.png',
				'images/logos/banking/bank5.png',
				'images/logos/banking/bank6.png',
				'images/logos/banking/bank7.png',
				'images/logos/banking/bank8.png',
				'images/logos/banking/bank9.png',
				'images/logos/banking/bank10.png',
			],
			newLabel: t('business.paymentOptions.newLabel'),
			title: t('business.paymentOptions.payNow.content.payNowEnables.payByBank'),
		},
		{
			description: t(
				'business.paymentOptions.payNow.content.payNowEnables.acceptMajorCreditAndDebitCards'
			),
			icon: faCreditCard,
			logos: ['images/logos/banking/visa.svg', 'images/logos/banking/mastercard.svg'],
			title: t('business.paymentOptions.payNow.content.payNowEnables.payByCard'),
		},
		{
			description: t('business.paymentOptions.payNow.content.payNowEnables.seamlesslyIntegrate'),
			icon: faMobileAlt,
			logos: ['images/logos/banking/applepay.svg', 'images/logos/banking/gpay.svg'],
			title: t('business.paymentOptions.payNow.content.payNowEnables.payByDevice'),
		},
	];

	return (
		<div className="border border-tertiary p-2 mt-3 rounded-xs lg:p-3">
			<Title4 weight="bold" className="mb-4">
				{t('business.paymentOptions.payNow.content.payNowEnables.title')}
			</Title4>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
				{cardsData.map((card) => (
					<div key={card.title}>
						<div>
							{card.icon === faBank ? (
								<FontAwesomeIcon icon={faBolt} size="2x" className="mr-1 mb-0.5" />
							) : null}
							<FontAwesomeIcon icon={card.icon} size="2x" className="mr-2 mb-0.5" />
							<div className="flex items-center">
								<Body weight="bold" className="font-bold">
									{card.title}
								</Body>
								{card.newLabel && (
									<span className="bg-primary text-sm px-0.5 py-0.25 rounded-xs ml-0.5">
										<FontAwesomeIcon icon={faSparkles} className="mr-0.25" />
										{card.newLabel}
									</span>
								)}
							</div>
						</div>
						<p className="text-gray-600 mb-1">{card.description}</p>
						<div className="flex flex-wrap gap-0.5">
							{card.logos.map((logo) => (
								<div
									key={logo}
									className="border border-tertiary rounded-xs flex items-center px-1 py-0.5"
								>
									<Image src={logo as string} width={32} height={32} className="" />
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default PayNowEnables;
