import { faBolt, faLandmark } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Image from '@/components/Image';
import { Body, Title2, Title4 } from '@/components/Text/index';

type IProps = {
	onTabChange?: (index: number) => void;
};

const PayPadMethods: FC<IProps> = ({ onTabChange }) => {
	const { t } = useTranslation('home');

	const brandImagePaths = [
		'images/logos/banking/visa.svg',
		'images/logos/banking/mastercard.svg',
		'images/logos/banking/applepay.svg',
		'images/logos/banking/gpay.svg',
	];

	const handleButtonClick = (index: number) => {
		if (onTabChange) {
			onTabChange(index);
		}
	};

	return (
		<div className="lg:mt-10">
			<div className="my-2">
				<Title4 className="font-sans" weight="bold">
					{t('business.paymentOptions.payPad.content.methods.title')}
				</Title4>
			</div>
			<div className="pt-1 lg:flex lg:gap-4">
				<div className="flex gap-1 mb-3 lg:mb-0 lg:w-1/2">
					<div className="w-1/2 lg:w-2/3">
						<Image src="images/logos/bumper-main.svg" height={20} width={79} />
						<Title2 variant="reverse" className="uppercase text-n7">
							{t('business.paymentOptions.payPad.content.methods.payLater')}
						</Title2>
						<Body className="my-2">
							{t('business.paymentOptions.payPad.content.methods.payLaterDesc')}
						</Body>

						<div className="hidden lg:block">
							<Button
								onClick={() => handleButtonClick(0)}
								label={t('business.paymentOptions.payPad.content.methods.explorePayLater')}
								width="content"
								type="button"
								size="medium"
								variant="tertiary"
							/>
						</div>
					</div>
					<div className="w-1/2 text-right lg:w-1/3">
						<Image src="images/home/businessanddriver/payPadSetup.png" width={190} height={430} />
					</div>
				</div>
				<div className="flex gap-1 lg:w-1/2">
					<div className="w-1/2 lg:w-1/3">
						<Image
							src="images/home/businessanddriver/payPadMobilePay.png"
							width={190}
							height={430}
						/>
					</div>
					<div className="w-1/2 lg:w-2/3">
						<Image remote src="images/logos/bumper-main.svg" height={20} width={79} />
						<Title2 variant="reverse" className="uppercase text-n7">
							{t('business.paymentOptions.payPad.content.methods.payNow')}
						</Title2>
						<Body className="my-2">
							{t('business.paymentOptions.payPad.content.methods.payNowDesc')}
						</Body>
						<div className="flex gap-0.5 mb-2 flex-wrap ">
							{brandImagePaths.map((brandImg) => (
								<div
									key={brandImg}
									className="px-0.5 border border-tertiary rounded-xs flex items-center"
								>
									<Image src={brandImg} width={40} height={40} />
								</div>
							))}
							<div className="p-0.75 border border-tertiary rounded-xs flex items-center gap-0.25 lg:px-0.5">
								<span className="hidden lg:block">
									{t('business.paymentOptions.payPad.content.methods.payByBank')}
								</span>
								<Icon iconName={faBolt} />
								<Icon iconName={faLandmark} />
							</div>
						</div>
						<div className="hidden lg:block">
							<Button
								onClick={() => handleButtonClick(1)}
								label={t('business.paymentOptions.payPad.content.methods.explorePayNow')}
								width="content"
								type="button"
								size="medium"
								variant="tertiary"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PayPadMethods;
