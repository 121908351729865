import PaymentOptionsTabs from '@/modules/Home/Business/PaymentOptions/Desktop';
import PaymentOptionButtons from '@/modules/Home/Business/PaymentOptions/Mobile';

const PaymentOptions = () => (
	<div>
		{/* Desktop Content */}
		<div className="hidden lg:block">
			<PaymentOptionsTabs />
		</div>

		{/* Mobile Content */}
		<div className="block lg:hidden">
			<PaymentOptionButtons />
		</div>
	</div>
);

export default PaymentOptions;
