import LandingHeader from '@/modules/Home/Business/LandingHeader';
import PaymentOptions from '@/modules/Home/Business/PaymentOptions';

const HomeForBusiness = () => (
	<>
		<LandingHeader />
		<PaymentOptions />
	</>
);
export default HomeForBusiness;
