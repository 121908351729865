import { faLongArrowRight } from '@fortawesome/pro-solid-svg-icons';
import router from 'next/router';
import { useTranslation } from 'next-i18next';

import Button from '@/components/Button';
import Image from '@/components/Image';
import { LargeButton, Title2, Title4 } from '@/components/Text/index';

const PayPadInfo = () => {
	const { t } = useTranslation('home');

	return (
		<div className="mb-4 lg:mb-0">
			<div>
				<Image remote src="images/logos/bumper-main.svg" height={20} width={79} />
				<Title2>{t('business.paymentOptions.payPad.content.info.title')}</Title2>
			</div>
			<div className="flex flex-col-reverse lg:flex-row lg:gap-3">
				<div className="lg:my-3 lg:w-1/2">
					<LargeButton>{t('business.paymentOptions.payPad.content.info.description')}</LargeButton>
					<div className="my-2">
						<Title4 variant="reverse" weight="bold">
							{t('business.paymentOptions.payPad.content.info.fastCheckouts')}
						</Title4>
						<Title4 variant="reverse" weight="bold">
							{t('business.paymentOptions.payPad.content.info.smoothPayments')}
						</Title4>
						<Title4 variant="reverse" weight="bold">
							{t('business.paymentOptions.payPad.content.info.lessWork')}
						</Title4>
					</div>
				</div>

				<div className="relative lg:w-1/2">
					<div className="hidden lg:block lg:absolute lg:-left-48 lg:-top-32">
						<Image src="images/home/businessanddriver/payPadPos.png" width={1600} height={1300} />
					</div>
					<div className="block lg:hidden">
						<Image
							src="images/home/businessanddriver/payPadPosMobile.webp"
							width={400}
							height={300}
							layout="responsive"
						/>
					</div>
				</div>
			</div>
			<Button
				onClick={() => {
					router.push('/registration/dealership');
				}}
				label={t('business.paymentOptions.payPad.content.info.registerBtnText')}
				icon={faLongArrowRight}
				width="content"
				type="button"
			/>
		</div>
	);
};

export default PayPadInfo;
