import { useTranslation } from 'next-i18next';
import React from 'react';

import Container from '@/components/Container';
import Image from '@/components/Image';
import { Body, Title2, Title4 } from '@/components/Text';

const OurNetwork = () => {
	const { t } = useTranslation('home');
	const networkCompanies = [
		'images/partner/partners-uk/ArnoldClark.svg',
		'images/partner/partners-uk/Sytner.svg',
		'images/partner/partners-uk/peterVardy.png',
		'images/partner/partners-uk/Group1.svg',
		'images/partner/partners-uk/Snows.svg',
		'images/partner/partners-uk/JohnClark.svg',
		'images/partner/partners-uk/bellsMotorGroup.png',
		'images/partner/partners-uk/Listers.svg',
		'images/partner/partners-uk/Vindis.svg',
	];
	const keyNumbers = [
		{
			description: t('forDrivers.ourNetwork.keyNumbers.firstDescription'),
			number: t('forDrivers.ourNetwork.keyNumbers.firstNumber'),
		},
		{
			description: t('forDrivers.ourNetwork.keyNumbers.secondDescription'),
			number: t('forDrivers.ourNetwork.keyNumbers.secondNumber'),
		},
		{
			description: t('forDrivers.ourNetwork.keyNumbers.thirdDescription'),
			number: t('forDrivers.ourNetwork.keyNumbers.thirdNumber'),
		},
		{
			description: t('forDrivers.ourNetwork.keyNumbers.fourthDescription'),
			number: t('forDrivers.ourNetwork.keyNumbers.fourthNumber'),
		},
	];

	return (
		<Container variant="accent" className="py-2">
			<div>
				<Body weight="bold">{t('forDrivers.ourNetwork.title')}</Body>
				<Title2 className="text-n4" weight="bold">
					{t('forDrivers.ourNetwork.youreInGreatCompany')}
				</Title2>
			</div>
			<div className="lg:flex lg:gap-3 lg:justify-between ">
				<div className="lg:w-1/2 lg:gap-2 lg:border-r lg:border-b-0 grid grid-cols-3 p-1 gap-1 border-b border-accent-contrast pr-3">
					{networkCompanies.map((company) => (
						<div key={company} className="text-center">
							<Image src={company} height={128} width={128} />
						</div>
					))}
				</div>

				<div className="lg:w-1/2 text-left mt-2 p-1">
					<Title4 weight="content" className="text-2xl mb-2 lg:mb-4">
						{t('forDrivers.ourNetwork.fewKeyNumbers')}
					</Title4>
					<div className="grid grid-cols-2 md:grid-cols-2 gap-3">
						{keyNumbers.map((item) => (
							<div key={item.number}>
								<Body className="text-n7 lg:text-n10" weight="heading">
									{item.number}
								</Body>
								<p>{item.description}</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</Container>
	);
};

export default OurNetwork;
