import { faLongArrowRight } from '@fortawesome/pro-solid-svg-icons';
import router from 'next/router';
import { useTranslation } from 'next-i18next';

import Button from '@/components/Button';
import Image from '@/components/Image';
import Label from '@/components/Label';
import Stepper from '@/components/Stepper';
import IStepper from '@/components/Stepper/index.d';
import { LargeButton, Title2, Title4 } from '@/components/Text/index';

const PayNowInfo = () => {
	const { t } = useTranslation('home');

	const stepsData = [
		{
			content: t('business.paymentOptions.payNow.content.info.stepOne.content'),
			id: 1,
			title: t('business.paymentOptions.payNow.content.info.stepOne.title'),
		},
		{
			content: t('business.paymentOptions.payNow.content.info.stepTwo.content'),
			id: 2,
			title: t('business.paymentOptions.payNow.content.info.stepTwo.title'),
		},
		{
			content: t('business.paymentOptions.payNow.content.info.stepThree.content'),
			id: 3,
			title: t('business.paymentOptions.payNow.content.info.stepThree.title'),
		},
	];

	return (
		<div>
			<div>
				<Image remote src="images/logos/bumper-main.svg" height={20} width={79} />
				<Title2>{t('business.paymentOptions.payNow.content.info.title')}</Title2>
			</div>
			<div className="flex flex-col-reverse lg:flex-row lg:gap-3">
				<div className="my-1 lg:my-3 lg:w-1/2">
					<LargeButton>{t('business.paymentOptions.payNow.content.info.description')}</LargeButton>
					<div className="my-2">
						<Title4 weight="bold" variant="reverse">
							{t('business.paymentOptions.payNow.content.info.acceptPayments')}
						</Title4>
					</div>
					<Label label={t('business.paymentOptions.payNow.content.info.stepsTitle')} /> <br />
					<Stepper itemList={stepsData as IStepper.IItem[]} />
					<div className="mt-3">
						<Button
							onClick={() => {
								router.push('/registration/dealership');
							}}
							label={t('business.paymentOptions.payNow.content.info.registerBtnText')}
							icon={faLongArrowRight}
							width="content"
							type="button"
						/>
					</div>
				</div>

				<div className="w-4/5 mt-1 flex justify-center mx-auto lg:mt-0 lg:w-1/2">
					<div className="hidden lg:block">
						<Image src="images/home/businessanddriver/payNowIphone.png" width={500} height={600} />
					</div>
					<div className="block lg:hidden">
						<Image
							src="images/home/businessanddriver/payNowIphoneMobile.png"
							width={266}
							height={348}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PayNowInfo;
