import LandingIntegrationWorkflow from '@/modules/Home/Business/LandingIntegrationWorkflow';
import PayLaterInfo from '@/modules/Home/Business/PaymentOptions/Desktop/PayLaterTabContent/PayLaterInfo';
import PayLaterStatisticsSection from '@/modules/Home/Business/PaymentOptions/Desktop/PayLaterTabContent/PayLaterStatisticsSection';
import TradeAndRetailCustomers from '@/modules/Home/Business/PaymentOptions/Desktop/PayLaterTabContent/TradeAndRetailCustomers';

const PayLaterTabContent = () => (
	<div className="lg:px-5">
		<PayLaterInfo />
		<PayLaterStatisticsSection />
		<TradeAndRetailCustomers />
		<LandingIntegrationWorkflow />
	</div>
);

export default PayLaterTabContent;
